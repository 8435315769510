/* eslint-disable */
import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
// import Typist from "react-typist";
import { useCallback, useEffect, useState } from "react";
import HearingIcon from "@mui/icons-material/Hearing";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { ReactMic } from "react-mic";
import audioBufferToWav from "audiobuffer-to-wav";
import { Mic } from "@mui/icons-material";
import logo from "@src/assets/logo.png";
import Footer from "@src/components/Footer";

const AudioVerification = () => {
  const [recordState, setRecordState] = useState(null);
  const [userId, setUserId] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const passcodeApp = null;
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userName = urlParams.get("userName");

  const navigate = useNavigate();
  const url = window.location.href;

  const extractParameterValue = useCallback((url, parameter) => {
    const pattern = new RegExp(`${parameter}=(\\d+)`);
    const match = url.match(pattern);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }, []);

  useEffect(() => {
    try {
      const userID = extractParameterValue(url, "userID");
      const meetingID = extractParameterValue(url, "meetingID");
      if (userID) setUserId(userID);
      if (meetingID) setMeetingId(meetingID);
    } catch (error) {
      console.log(error);
      navigate("/404");
    }
  }, [extractParameterValue, navigate, url]);

  useEffect(() => {
    if (recordState) setTimeout(() => setRecordState(false), 4000);
  }, [recordState]);

  const getVoiceVerification = useCallback(
    async (id, meeting_id) => {
      try {
        const response = await axios.post(`/userVerificationStatus/${id}`, {
          meeting_id: parseInt(meeting_id),
          type: "AUDIO_VERIFICATION",
        });
        return response.data?.verification_status;
      } catch (error) {
        console.log("error", error);
        return false;
      }
    },
    [userId]
  );

  const requestMicrophoneAccess = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // User granted microphone access
      // You can proceed with your audio recording logic here
    } catch (error) {
      // User denied microphone access or there was an error
      // Handle the error or display a message to the user
      console.log("Error accessing microphone:", error);
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (userId && meetingId) {
      (async () => {
        try {
          const isVerified = await getVoiceVerification(userId, meetingId);
          if (!isVerified) {
            await requestMicrophoneAccess();
          } else {
            joinAppointment();
          }
          setLoader(false);
        } catch (error) {
          console.log(error);
          navigate("/404");
        }
      })();
    }
  }, [userId, meetingId]);

  const handleAudioData = async (data) => {
    try {
      const audioContext = new window.AudioContext();
      const audioData = await data.blob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(audioData);
      const wavData = audioBufferToWav(audioBuffer);
      const wavBlob = new Blob([wavData], { type: "audio/wav" });
      const formData = new FormData();
      formData.append("file", wavBlob, "audioInterval.wav");
      formData.append("media_type", "AUDIO_VERIFICATION");
      formData.append("meeting_id", meetingId);
      const voiceVerifyApi = await axios.post(
        `/audio_verification/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log("error => ", err);
    }
  };
  const joinAppointment = () => {
    navigate(
      `/joinMeeting/:meetingID=${meetingId}/:passcode=${passcodeApp}/:userID=${userId}`,
      {
        replace: true,
      }
    );
  };
  const brandingData = JSON.parse(localStorage.getItem("brandingData"));

  return !loader ? (
    <>
      \
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Paper
          sx={{
            padding: { xs: 2, sm: 4, md: 5, lg: 8 },
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack
            justifyContent="flex-start"
            alignItems="center"
            sx={{ position: "relative", height: "100%" }}
          >
            <Box
              component={"img"}
              src={(brandingData && brandingData.logo_url) ?? logo}
              alt="Logo"
              sx={{
                width: "100%",
                maxWidth: { xs: 300, md: 400 },
                height: "auto",
                margin: "20px",
              }}
            />
            <Box sx={{ mt: { xs: 3, md: 7, lg: 8 } }}>
              {error ? (
                <>
                  <Typography variant="h6">
                    Microphone Access Required
                  </Typography>
                  <Typography component="span">
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      "{" "}
                    </span>
                    This web app requires
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      access{" "}
                    </span>
                    to your
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      microphone{" "}
                    </span>
                    to function properly.
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Please{" "}
                    </span>
                    grant
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      microphone permission{" "}
                    </span>
                    in your browser settings.
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      "
                    </span>
                  </Typography>
                </>
              ) : (
                <>
                  {/* <Typist avgTypingDelay={2} cursor={{ hideWhenDone: true }}> */}
                  <Typography component="span">
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      "
                    </span>
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      Please{" "}
                    </span>
                    tap the
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      mic{" "}
                    </span>
                    below
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      ,{" "}
                    </span>
                    then say your
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      name{" "}
                    </span>
                    and{" "}
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      date of birth{" "}
                    </span>
                    before joining your appointment.
                    <span
                      style={{
                        color: "purple",
                        fontWeight: "bold",
                      }}
                    >
                      "
                    </span>
                  </Typography>
                  {/* </Typist> */}
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                  >
                    {recordState && <HearingIcon color="action" />}
                    <Typography
                      style={{
                        color: "gray",
                        textAlign: "center",
                      }}
                      m={1}
                    >
                      {recordState
                        ? "listening..."
                        : recordState === false
                        ? "Thank You!"
                        : "Tap here"}
                    </Typography>
                  </Stack>
                  {!(recordState === false) && (
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconButton
                        color="primary"
                        aria-label="Mic"
                        // onClick={() => setRecordState(true)}
                        onClick={() => {
                          if (!recordState) setRecordState(true);
                          else setRecordState(false);
                        }}
                        size="large"
                        sx={{
                          height: { xs: 65, sm: 80 },
                          width: { xs: 65, sm: 80 },
                          bgcolor: recordState ? "#ECECEC" : "#F3F3F3",
                          paddingBottom: 1,
                        }}
                      >
                        <Mic
                          fontSize="large"
                          color="primary"
                          sx={{
                            color: recordState && "#ea4335",
                            fontSize: 35,
                          }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                overflow: "hidden",
                py: 2,
              }}
            >
              <ReactMic
                record={recordState ? recordState : false}
                className="sound-wave"
                onStop={(data) => handleAudioData(data)}
                // onBlock={() => setError(true)}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              disabled={!(recordState === false)}
              sx={{ alignSelf: "end", mt: 4 }}
              onClick={joinAppointment}
            >
              Join Appointment
            </Button>
          </Stack>
        </Paper>
      </Box>
      <Footer />
    </>
  ) : (
    <Box
      style={{
        minHeight: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AudioVerification;
