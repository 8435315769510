/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Typography, Paper, Box } from "@mui/material";
import logo from "@src/assets/logo.png";

const Home = () => {
  const brandingData = JSON.parse(localStorage.getItem("brandingData"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Paper
        sx={{
          padding: { xs: 2, sm: 4, md: 5, lg: 8 },
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            component="img"
            src={(brandingData && brandingData.logo_url) ?? logo}
            alt="Logo"
            sx={{
              width: "100%",
              maxWidth: { xs: 200, sm: 300, md: 400 },
              height: "auto",
              margin: "20px",
            }}
          />
          <Typography
            sx={{
              fontSize: { sm: 25 },
            }}
          >
            Login to our app to go to your appointment.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Home;
