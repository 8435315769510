import { Box, Container, Grid, Typography } from "@mui/material";
import BrandLogo from "@src/assets/logo.png";
import Footer from "@src/components/Footer";
import EXPIMAGE from "@src/assets/exp.png";
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { extractParameterValue } from "@src/utils/extractParams";

const WaitingRoom = ({ rcvEngine, isMeetingJoined }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const url = window.location.href;
  const meetingID = extractParameterValue(url, "meetingID");
  const joinMeetingHandler = useCallback(async () => {
    if (rcvEngine) {
      try {
        rcvEngine.joinMeeting(meetingID, {
          password: "",
        });
      } catch (e) {
        setError(e.message);
        enqueueSnackbar(`Error occurs due to :${e.message}`, {
          variant: "error",
        });
      }
    }
  }, [rcvEngine]);
  useEffect(() => {
    if (!isMeetingJoined) {
      joinMeetingHandler();
    }
  }, [rcvEngine, meetingID]);
  const brandingData = JSON.parse(localStorage.getItem("brandingData"));

  return (
    <Box display="flex" flexDirection="column" minHeight="90vh">
      <Container>
        <Grid
          container
          spacing={4}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item xs={12}>
                <Box
                  component="img"
                  src={(brandingData && brandingData.logo_url) ?? BrandLogo}
                  alt="Logo"
                  sx={{ width: "100%", maxWidth: "568px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="h3"
                variant="h3"
                sx={{
                  maxWidth: "820px",
                  fontSize: { xs: "24px", md: "34px" },
                }}
              >
                You are checked in, and in the waiting room for your
                appointment. When the doctor arrives, the meeting will begin
                automatically.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                component="h5"
                variant="h5"
                sx={{
                  maxWidth: "850px",
                  fontSize: { xs: "20px", md: "24px" },
                }}
              >
                To ensure a smooth and timely appointment, please take a moment
                to confirm your audio and video settings are setup correctly.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default WaitingRoom;
