import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import {
  RcvEngine,
  EngineEvent,
  ErrorCodeType,
  GrantType,
  AttendeeStatus,
} from "@ringcentral/video-sdk";
// import StartView from "./pages/StartView";
import InMeeting from "./pages/InMeeting";
import GlobalContext from "./store/global/context";
import { MeetingContextProvider } from "./store/meeting";
import { ElementContextProvider } from "./store/element";
import "./styles/index.less";
import AudioVerification from "./pages/meeting/audioVerification";
import { UserContextProvider } from "./store/user";
import LoadinScreen from "./pages/Loading";
import EndMeetingPage from "./pages/EndMeetingPage";
import Home from "./pages/Home";
import WaitingRoom from "./pages/WaitingRoom";
import axios from "./utils/axios";

declare global {
  interface Window {
    initConfig: Record<string, string>;
  }
}

export default function App({ config }) {
  const [rcvEngine, setRcvEngine] = useState(null);
  const [isMeetingJoined, setMeetingJoined] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initSDK = async () => {
      const { clientId, clientSecret, jwt, userName, password, token } = config;

      const engine = RcvEngine.create({
        clientId,
        clientSecret,
        enableDiscovery: false,
      });

      if (token) {
        engine.setAuthToken(JSON.stringify(token));
      } else {
        await engine.authorize({
          grantType: jwt ? GrantType.JWT : GrantType.PASSWORD,
          jwt,
          username: userName,
          password,
        });
      }

      engine.on(EngineEvent.MEETING_JOINED, async (meetingId, errorCode) => {
        if (errorCode === ErrorCodeType.ERR_WAITING_FOR_HOST) {
          const body = JSON.stringify({
            meeting_id: meetingId,
          });
          try {
            await axios.post("/ringcentral/waiting_notification", body);
          } catch (e) {
            console.log(e);
          } finally {
            navigate(`/waitingRoom/:meetingID=${meetingId}`, { replace: true });
          }
        } else if (errorCode === ErrorCodeType.ERR_OK) {
          setMeetingJoined(true);
          if (!window.location.pathname.includes("/meeting/")) {
            navigate(`/meeting/${meetingId}`, { replace: true });
          }
        }
      });
      engine.on(EngineEvent.MEETING_LEFT, () => {
        navigate("endMeeting/", { replace: true });
      });
      console.log(AttendeeStatus.IN_WAITING_ROOM);

      setRcvEngine(engine);
    };

    initSDK();
  }, []);

  return (
    <SnackbarProvider autoHideDuration={3000} preventDuplicate>
      <GlobalContext.Provider value={{ rcvEngine, isMeetingJoined }}>
        <UserContextProvider>
          <ElementContextProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="meeting">
                <Route
                  path=":meetingId"
                  element={
                    <MeetingContextProvider>
                      <InMeeting />
                    </MeetingContextProvider>
                  }
                />
              </Route>
              <Route
                path="joinMeeting/:meetingID?/:passcode?/:userID?"
                element={<LoadinScreen rcvEngine={rcvEngine} />}
              />
              <Route
                path="audioVerification/:meetingID?/:userID?"
                element={<AudioVerification />}
              />
              <Route
                path="waitingRoom/:meetingID?"
                element={
                  <WaitingRoom
                    rcvEngine={rcvEngine}
                    isMeetingJoined={isMeetingJoined}
                  />
                }
              />
              <Route path="endMeeting" element={<EndMeetingPage />} />
            </Routes>
          </ElementContextProvider>
        </UserContextProvider>
      </GlobalContext.Provider>
    </SnackbarProvider>
  );
}

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <App
      config={{
        ...window.initConfig,
      }}
    />
  </BrowserRouter>
);
