import type { IParticipant } from "@ringcentral/video-sdk";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useAvatarContext } from "@src/store/avatar";
import { useSnackbar } from "notistack";
import { Avatar as MUIAvatar, Typography, useMediaQuery } from "@mui/material";

interface IAvatarProps {
  participant: IParticipant;
  displaySize?: number | string;
  imgSize?: number;
}

const Avatar: FC<IAvatarProps> = ({
  participant,
  displaySize = 45,
  imgSize = 45,
}: IAvatarProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getAvatar: getAvatarFromStore, saveAvatar: saveAvatarToStore } =
    useAvatarContext();
  const [url, setUrl] = useState<string>("");

  /**
   * need cache avatars, otherwise, the backend will cause a 429 error if the avatar is requested frequently
   */
  const isWidthGt850 = useMediaQuery("(min-width:768px)");
  const getAvatar = async () => {
    const url = getAvatarFromStore(participant.uid, imgSize);
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (url) {
      setUrl(url);
    } else {
      try {
        const avatarUrl = await participant.getHeadshotUrlWithSize(imgSize);
        const avatarRes = await fetch(avatarUrl);
        const blobUrl = URL.createObjectURL(await avatarRes.blob());
        setUrl(blobUrl);
        saveAvatarToStore(participant.uid, imgSize, blobUrl);
      } catch (e) {
        enqueueSnackbar("Get avatar failed", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    getAvatar();
  }, []);

  return url ? (
    <MUIAvatar
      variant="circular"
      sx={{
        width: displaySize,
        height: displaySize,
      }}
    >
      <Typography variant="h2">
        {participant.displayName
          .split(" ")
          .map((name, index) =>
            index < 2 ? name.slice(0, 1).toUpperCase() : ""
          )}
      </Typography>
    </MUIAvatar>
  ) : (
    <div
      style={{ borderRadius: "50%", width: displaySize, height: displaySize }}
    ></div>
  );
};

export default Avatar;
