import React from "react";
import Lottie from "react-lottie";
import { ErrorJson } from "./error";

export default function ErrorLottie({ height, width, ...rest }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ErrorJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie options={defaultOptions} height={height} width={width} {...rest} />
  );
}
