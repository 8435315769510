export const LoaderJson = {
  nm: "load",
  v: "5.9.6",
  fr: 60,
  ip: 0,
  op: 150,
  w: 1024,
  h: 1024,
  ddd: 0,
  markers: [],
  assets: [
    {
      nm: "[FRAME CLIPS CONTENT] load - Null / Ellipse 7 - Null / Ellipse 7 - Stroke / Ellipse 4 - Null / Ellipse 4 - Stroke / Ellipse 3 - Null / Ellipse 3 - Stroke",
      fr: 60,
      id: "lhl6e5gozimw3nrt97h",
      layers: [
        {
          ty: 3,
          ddd: 0,
          ind: 76834,
          hd: false,
          nm: "load - Null",
          ks: {
            a: { a: 0, k: [0, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [0, 0] },
            r: { a: 0, k: 0 },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
        },
        {
          ty: 3,
          ddd: 0,
          ind: 95179,
          hd: false,
          nm: "Ellipse 7 - Null",
          parent: 76834,
          ks: {
            a: { a: 0, k: [149, 0] },
            o: { a: 0, k: 100 },
            p: { a: 0, k: [514, 511] },
            r: {
              a: 1,
              k: [
                {
                  t: 0,
                  s: [-90],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 43.674,
                  s: [-360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                { t: 87.48599999999999, s: [360] },
              ],
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
        },
        {
          ddd: 0,
          ind: 76501,
          hd: false,
          nm: "Ellipse 7 - Stroke",
          parent: 95179,
          ks: {
            a: { a: 0, k: [0, 0] },
            p: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            r: { a: 0, k: 0 },
            o: { a: 0, k: 100 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
          ty: 4,
          shapes: [
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "sh",
                  nm: "Path",
                  hd: false,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      v: [
                        [298, 0],
                        [149, 149],
                        [0, 0],
                      ],
                      i: [
                        [0, 0],
                        [82.2904, 0],
                        [0, 82.2904],
                      ],
                      o: [
                        [0, 82.2904],
                        [-82.29043, 0],
                        [0, 0],
                      ],
                    },
                  },
                },
                {
                  ty: "st",
                  o: { a: 0, k: 100 },
                  w: {
                    a: 1,
                    k: [
                      {
                        t: 43.674,
                        s: [41],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 64.716,
                        s: [23],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      { t: 87.48599999999999, s: [60] },
                    ],
                  },
                  c: {
                    a: 0,
                    k: [0.23921568627450981, 0.6784313725490196, 1, 1],
                  },
                  ml: 4,
                  lc: 2,
                  lj: 2,
                  nm: "Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "rc",
                  nm: "Rectangle",
                  hd: false,
                  p: { a: 0, k: [149, 74.5] },
                  s: { a: 0, k: [380, 231] },
                  r: { a: 0, k: 0 },
                },
                {
                  ty: "fl",
                  o: { a: 0, k: 0 },
                  c: { a: 0, k: [0, 1, 0, 1] },
                  nm: "Fill",
                  hd: false,
                  r: 1,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
          ],
        },
        {
          ty: 3,
          ddd: 0,
          ind: 1608,
          hd: false,
          nm: "Ellipse 4 - Null",
          parent: 76834,
          ks: {
            a: { a: 0, k: [248, 0] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                {
                  t: 87.48599999999999,
                  s: [511, 513],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                  ti: [0, 0],
                  to: [0, 0],
                },
                { t: 119.4, s: [624, 430] },
              ],
            },
            r: {
              a: 1,
              k: [
                {
                  t: 0,
                  s: [90],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 43.674,
                  s: [360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 87.48599999999999,
                  s: [-360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 87.936,
                  s: [-360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                { t: 119.4, s: [-180] },
              ],
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
        },
        {
          ddd: 0,
          ind: 11458,
          hd: false,
          nm: "Ellipse 4 - Stroke",
          parent: 1608,
          ks: {
            a: { a: 0, k: [0, 0] },
            p: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            r: { a: 0, k: 0 },
            o: { a: 0, k: 100 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
          ty: 4,
          shapes: [
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "sh",
                  nm: "Path",
                  hd: false,
                  ks: {
                    a: 1,
                    k: [
                      {
                        t: 87.48599999999999,
                        s: [
                          {
                            c: false,
                            v: [
                              [496, 0],
                              [248, 248],
                              [0, 0],
                            ],
                            i: [
                              [0, 0],
                              [136.9666, 0],
                              [0, 136.9666],
                            ],
                            o: [
                              [0, 136.96657],
                              [-136.96662, 0],
                              [0, 0],
                            ],
                          },
                        ],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 119.4,
                        s: [
                          {
                            c: false,
                            v: [
                              [288.5, 0],
                              [248, 40.5],
                              [207.5, 0],
                            ],
                            i: [
                              [0, 0],
                              [22.3675, 0],
                              [0, 22.3675],
                            ],
                            o: [
                              [0, 22.36753],
                              [-22.367529999999988, 0],
                              [0, 0],
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  ty: "st",
                  o: { a: 0, k: 100 },
                  w: {
                    a: 1,
                    k: [
                      {
                        t: 43.674,
                        s: [41],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 64.716,
                        s: [23],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 87.48599999999999,
                        s: [60],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 87.6,
                        s: [60],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      { t: 119.4, s: [30] },
                    ],
                  },
                  c: {
                    a: 0,
                    k: [1, 0.6313725490196078, 0.6313725490196078, 1],
                  },
                  ml: 4,
                  lc: 2,
                  lj: 2,
                  nm: "Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "rc",
                  nm: "Rectangle",
                  hd: false,
                  p: { a: 0, k: [248, 124] },
                  s: { a: 0, k: [578, 330] },
                  r: { a: 0, k: 0 },
                },
                {
                  ty: "fl",
                  o: { a: 0, k: 0 },
                  c: { a: 0, k: [0, 1, 0, 1] },
                  nm: "Fill",
                  hd: false,
                  r: 1,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
          ],
        },
        {
          ty: 3,
          ddd: 0,
          ind: 70657,
          hd: false,
          nm: "Ellipse 3 - Null",
          parent: 76834,
          ks: {
            a: { a: 0, k: [316.5, 0] },
            o: { a: 0, k: 100 },
            p: {
              a: 1,
              k: [
                {
                  t: 87.48599999999999,
                  s: [511.5, 507],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                  ti: [0, 0],
                  to: [0, 0],
                },
                { t: 119.4, s: [423.5, 433] },
              ],
            },
            r: {
              a: 1,
              k: [
                {
                  t: 0,
                  s: [0],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 43.674,
                  s: [-360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 87.48599999999999,
                  s: [360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                {
                  t: 87.936,
                  s: [360],
                  o: { x: [0.5], y: [0.35] },
                  i: { x: [0.15], y: [1] },
                },
                { t: 119.4, s: [542] },
              ],
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
        },
        {
          ddd: 0,
          ind: 57145,
          hd: false,
          nm: "Ellipse 3 - Stroke",
          parent: 70657,
          ks: {
            a: { a: 0, k: [0, 0] },
            p: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            r: { a: 0, k: 0 },
            o: { a: 0, k: 100 },
          },
          st: 0,
          ip: 0,
          op: 150,
          bm: 0,
          sr: 1,
          ty: 4,
          shapes: [
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "sh",
                  nm: "Path",
                  hd: false,
                  ks: {
                    a: 1,
                    k: [
                      {
                        t: 87.48599999999999,
                        s: [
                          {
                            c: false,
                            v: [
                              [633, 0],
                              [316.5, 316],
                              [0, 0],
                            ],
                            i: [
                              [0, 0],
                              [174.7981, 0],
                              [0, 174.5219],
                            ],
                            o: [
                              [0, 174.52192],
                              [-174.79813, 0],
                              [0, 0],
                            ],
                          },
                        ],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 119.4,
                        s: [
                          {
                            c: false,
                            v: [
                              [362.5, 0],
                              [316.5, 45.9273],
                              [270.5, 0],
                            ],
                            i: [
                              [0, 0],
                              [25.4051, 0],
                              [0, 25.3649],
                            ],
                            o: [
                              [0, 25.36494],
                              [-25.405100000000004, 0],
                              [0, 0],
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  ty: "st",
                  o: { a: 0, k: 100 },
                  w: {
                    a: 1,
                    k: [
                      {
                        t: 43.674,
                        s: [41],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 64.716,
                        s: [23],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 87.48599999999999,
                        s: [60],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      {
                        t: 87.6,
                        s: [60],
                        o: { x: [0.5], y: [0.35] },
                        i: { x: [0.15], y: [1] },
                      },
                      { t: 119.4, s: [30] },
                    ],
                  },
                  c: {
                    a: 0,
                    k: [0.5450980392156862, 0.43137254901960786, 1, 1],
                  },
                  ml: 4,
                  lc: 2,
                  lj: 2,
                  nm: "Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
            {
              ty: "gr",
              nm: "Group",
              hd: false,
              np: 3,
              it: [
                {
                  ty: "rc",
                  nm: "Rectangle",
                  hd: false,
                  p: { a: 0, k: [316.5, 158] },
                  s: { a: 0, k: [715, 398] },
                  r: { a: 0, k: 0 },
                },
                {
                  ty: "fl",
                  o: { a: 0, k: 0 },
                  c: { a: 0, k: [0, 1, 0, 1] },
                  nm: "Fill",
                  hd: false,
                  r: 1,
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0] },
                  p: { a: 0, k: [0, 0] },
                  s: { a: 0, k: [100, 100] },
                  sk: { a: 0, k: 0 },
                  sa: { a: 0, k: 0 },
                  r: { a: 0, k: 0 },
                  o: { a: 0, k: 100 },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  layers: [
    {
      ty: 3,
      ddd: 0,
      ind: 76834,
      hd: false,
      nm: "load - Null",
      ks: {
        a: { a: 0, k: [0, 0] },
        o: { a: 0, k: 100 },
        p: { a: 0, k: [0, 0] },
        r: { a: 0, k: 0 },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
      },
      st: 0,
      ip: 0,
      op: 150,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ty: 0,
      nm: "load",
      refId: "lhl6e5gozimw3nrt97h",
      sr: 1,
      ks: {
        a: { a: 0, k: [0, 0] },
        p: { a: 0, k: [0, 0] },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ao: 0,
      w: 1024,
      h: 1024,
      ip: 0,
      op: 150,
      st: 0,
      hd: false,
    },
  ],
  meta: { a: "", d: "", tc: "", g: "Aninix" },
};
