import { IUserState, UserReduceType, IUserAction } from "./types";

export function userReducer(state: IUserState, { type, payload }: IUserAction) {
  if (payload) {
    switch (type) {
      case UserReduceType.USER_DATA:
        return {
          ...state,
          userData: payload.userData,
          isAdmin: payload.isAdmin ?? false,
        };
      case UserReduceType.BRANDING_DATA:
        return {
          ...state,
          brandingData: payload.brandingData,
        };
      default:
        return state;
    }
  }
}
