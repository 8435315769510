import React, {
  createContext,
  useContext,
  useReducer,
  PropsWithChildren,
} from "react";
import { IUserState, IUserContext } from "./types";
import { userReducer } from "./reducer";
/* eslint-disable-next-line padded-blocks */
const initState: IUserState = {
  userData: {},
  brandingData: {},
  isAdmin: false,
};
export const UserContext = createContext<IUserContext>({
  state: initState,
  dispatch: () => {},
});

export const UserContextProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const [state, dispatch] = useReducer(userReducer, initState);
  return (
    /* eslint-disable-next-line padded-blocks */
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext<IUserContext>(UserContext);
export { useUserContext };
