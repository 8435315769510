import React, { FC, useCallback, useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { RcvEngine } from "@ringcentral/video-sdk";
import { useSnackbar } from "notistack";
import { extractParameterValue } from "@src/utils/extractParams";
import ErrorLottie from "@src/components/lottie/Error";
import LoaderLottie from "@src/components/lottie/Loader";
interface IProps {
  rcvEngine: RcvEngine;
}
import logo from "@src/assets/logo.png";
import axios from "@src/utils/axios";
import { UserReduceType, useUserContext } from "@src/store/user";
import Footer from "@src/components/Footer";

const LoadinScreen: FC<IProps> = (props) => {
  const { rcvEngine } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isJoinLoading, setJoinLoading] = useState(false);
  const [error, setError] = useState(null);

  const url = window.location.href;
  const { dispatch } = useUserContext();

  const meetingID = extractParameterValue(url, "meetingID");
  const userID = extractParameterValue(url, "userID");
  const getUserData = useCallback(async () => {
    try {
      const resp = await axios.get(`get-user-details/${meetingID}`);
      dispatch({
        type: UserReduceType.USER_DATA,
        payload: { userData: resp.data, isAdmin: false },
      });
      if (resp?.data?.data?.length) {
        console.log(resp.data?.data);
      }
    } catch (error) {
      console.log("incode onboarding =>", error);
    }
  }, [dispatch, meetingID]);

  const getBrandingData = useCallback(async () => {
    try {
      const resp = await axios.get(`get-company-branding/${userID}`);
      localStorage.setItem("brandingData", JSON.stringify(resp.data.data));
      dispatch({
        type: UserReduceType.BRANDING_DATA,
        payload: { brandingData: resp.data.data },
      });
      if (resp?.data?.data?.length) {
        console.log(resp.data?.data);
      }
    } catch (e) {
      setError(e.message);
      enqueueSnackbar(`Error occurs due to :${e.message}`, {
        variant: "error",
      });
    }
  }, [dispatch, userID]);

  const joinMeetingHandler = useCallback(async () => {
    if (rcvEngine) {
      setJoinLoading(true);

      try {
        rcvEngine.joinMeeting(meetingID, {
          password: "",
        });
      } catch (e) {
        setError(e.message);
        enqueueSnackbar(`Error occurs due to :${e.message}`, {
          variant: "error",
        });
      } finally {
        setJoinLoading(false);
      }
    }
  }, [rcvEngine]);

  useEffect(() => {
    joinMeetingHandler();
    getUserData();
    getBrandingData();
  }, [rcvEngine, meetingID]);
  const brandingData = JSON.parse(localStorage.getItem("brandingData"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Paper
          sx={{
            padding: { xs: 2, sm: 4, md: 5, lg: 8 },
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={(brandingData && brandingData.logo_url) ?? logo}
              alt="Logo"
              sx={{
                width: "100%",
                maxWidth: { xs: 200, sm: 300, md: 400 },
                height: "auto",
                margin: "20px",
              }}
            />
            {error ? (
              <ErrorLottie height={300} width={300} />
            ) : (
              <LoaderLottie height={300} width={300} rest={undefined} />
            )}
          </Box>
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default LoadinScreen;
