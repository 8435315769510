import React from "react";
import Lottie from "react-lottie";
import { LoaderJson } from "./loader";

export default function LoaderLottie({ height, width, rest }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie options={defaultOptions} height={height} width={width} {...rest} />
  );
}
