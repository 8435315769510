import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import RINGCENTRALSVG from "@src/assets/ringcentral.svg";
import PATIENTVERIFI from "@src/assets/patientVerifi.png";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "auto",
      }}
      component="footer"
    >
      <Grid
        container
        spacing={1}
        direction={"column"}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12}>
          <Typography variant="body2">Powered by</Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            justifyContent="space-evenly"
            alignItems="center"
            direction="row"
          >
            <Box
              component="img"
              src={RINGCENTRALSVG}
              alt="ringcentral"
              sx={{ height: "25px" }}
            />
            <Typography variant="p" sx={{ px: 1 }}>
              &
            </Typography>
            <Box
              component="img"
              src={PATIENTVERIFI}
              alt="ringcentral"
              sx={{ height: "25px" }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
