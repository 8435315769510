import React, { FC } from "react";
import AudioAction from "./audio-action";
import VideoAction from "./video-action";
import LeaveAction from "./leave-action";
import MeetingInfoAction from "./meeting-info-action";
import "./index.less";
import { useMediaQuery } from "@mui/material";
import BrandLogo from "@src/assets/brand.png";

const ActionBar: FC = () => {
  const isWidthGt850 = useMediaQuery("(min-width:768px)");
  const brandingData = JSON.parse(localStorage.getItem("brandingData"));

  return (
    <div className="meeting-action-bar">
      <div className={isWidthGt850 ? "action-group" : "left-action-group"}>
        <AudioAction />
        <VideoAction />
        <LeaveAction />
      </div>
      <div className="right-action-group">
        <img
          src={(brandingData && brandingData.logo_url) ?? BrandLogo}
          alt="Logo"
          style={isWidthGt850 ? { height: "80%" } : { height: "60%" }}
        />
      </div>
    </div>
  );
};

export default ActionBar;
