import { Dispatch } from "react";
/* eslint-disable-next-line padded-blocks */
export enum ActiveFeatureModal {
  Participant,
  Chat,
  LiveTranscprition,
}

export enum UserReduceType {
  USER_DATA = "USER_DATA",
  BRANDING_DATA = "BRANDING_DATA",
}

export interface IUserState {
  userData: object | any;
  isAdmin: boolean | any;
  brandingData: object | any;
}

export interface IUserAction {
  type: UserReduceType;
  payload?: Partial<IUserState>;
}

export interface IUserContext {
  state: IUserState;
  dispatch: Dispatch<IUserAction>;
}
