export const ErrorJson = {
  "v": "5.7.1",
  "fr": 30,
  "ip": 0,
  "op": 110,
  "w": 320,
  "h": 190,
  "nm": "error",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "bottom",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [160, 95, 0], "ix": 2 },
        "a": { "a": 0, "k": [160, 95, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-2.761, 0],
                        [0, -2.762],
                        [2.762, 0],
                        [0, 2.762]
                      ],
                      "o": [
                        [2.762, 0],
                        [0, 2.762],
                        [-2.761, 0],
                        [0, -2.762]
                      ],
                      "v": [
                        [0, -5],
                        [5, 0],
                        [0, 5],
                        [-5, 0]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [160.001, 158], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": -2,
                        "s": [100]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 1,
                        "s": [60]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 4,
                        "s": [100]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 8,
                        "s": [60]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 12,
                        "s": [100]
                      },
                      { "t": 16, "s": [40] }
                    ],
                    "ix": 7
                  },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "light",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-2.632, 0],
                        [0, 0],
                        [0, -2.631],
                        [0, 0],
                        [2.631, 0],
                        [0, 0],
                        [0, 2.631],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [2.631, 0],
                        [0, 0],
                        [0, 2.631],
                        [0, 0],
                        [-2.632, 0],
                        [0, 0],
                        [0, -2.631]
                      ],
                      "v": [
                        [-32.235, -24.5],
                        [32.235, -24.5],
                        [37, -19.735],
                        [37, 19.735],
                        [32.235, 24.5],
                        [-32.235, 24.5],
                        [-37, 19.735],
                        [-37, -19.735]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.757000014361, 0.757000014361, 0.757000014361, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [160, 157.499], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "mac",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160, 93.5], "ix": 2 },
              "a": { "a": 0, "k": [160, 95], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "mac",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-34.836, -7.15],
                    [32.752, -8],
                    [61, 8],
                    [-61, 8]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [158.999, 180.5], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 12, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "bottom_shadow",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-1.498, 0.001],
                    [0, 0],
                    [-0.989, -1.128],
                    [0, 0],
                    [0.774, -0.677],
                    [0.451, 0],
                    [0, 0],
                    [0, 1.028],
                    [-0.297, 0.339],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [1.499, 0],
                    [0, 0],
                    [0.678, 0.774],
                    [-0.339, 0.298],
                    [0, 0],
                    [-1.028, 0],
                    [0.001, -0.451],
                    [0, 0],
                    [0.988, -1.127]
                  ],
                  "v": [
                    [-123.968, -8],
                    [123.746, -8],
                    [127.662, -6.226],
                    [137.424, 4.911],
                    [137.25, 7.538],
                    [136.024, 8],
                    [-136.24, 8],
                    [-138.102, 6.138],
                    [-137.64, 4.911],
                    [-127.879, -6.226]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.9333333333333333, 0.9333333333333333, 0.9333333333333333, 1
                ],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160.109, 180.5], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "bg_bottom",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 110,
      "st": -2,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "headache",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 60, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [155, 100, 0], "ix": 2 },
        "a": { "a": 0, "k": [160, 95, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [
                [0, 0],
                [3.14, -1.145],
                [2.544, -1.201],
                [2.488, -5.051],
                [-0.266, -1.374],
                [-1.182, -0.519],
                [-2.676, 0.847],
                [-2.871, 1.549],
                [-2.683, 1.767],
                [-2.383, 2.055],
                [-0.948, 1.272],
                [1.192, 0.805],
                [0.599, 0.005],
                [0.798, -0.01],
                [1.487, -0.319],
                [2.794, -1.464],
                [4.42, -4.292],
                [1.386, -2.733],
                [-2.038, -2.095],
                [-1.229, -0.119],
                [-1.454, 0.169],
                [-2.743, 1.396],
                [-2.559, 1.734],
                [-2.035, 2.025],
                [-0.813, 1.16],
                [-0.369, 0.66],
                [-0.06, 0.752],
                [1.048, 0.115],
                [1.436, -0.488],
                [2.584, -1.458],
                [2.248, -1.917],
                [1.018, -1.138],
                [0.457, -1.341],
                [-2.294, -0.234],
                [-2.634, 1.455],
                [-2.302, 1.855],
                [-1.509, 2.478],
                [0.039, 0.584],
                [0.529, 0.108],
                [1.161, -0.754],
                [1.129, -1.046],
                [1.061, -1.178],
                [0.809, -1.322],
                [0.194, -1.464],
                [-1.835, 0.731],
                [-1.177, 0.938],
                [-1.012, 1.035],
                [-0.709, 1.233],
                [0.559, 1.237],
                [1.049, -0.22],
                [1.038, -1.079],
                [-1.725, -1.777],
                [-1.707, 1.447],
                [0.093, 1.278],
                [0.973, -0.164],
                [0.092, -0.032],
                [0.179, -1.223],
                [-0.826, -0.798],
                [-1.033, 2.251],
                [0.216, -0.468],
                [1.215, -0.01],
                [-0.15, 1.401],
                [-1.139, 0.181],
                [0.593, -0.515],
                [1.159, -0.176],
                [0.467, 1.26],
                [-1.703, 0.991],
                [-1.014, -0.387],
                [0.443, -0.765],
                [2.001, -1.482],
                [2.627, 0.21],
                [0.405, 0.429],
                [-0.312, 0.642],
                [-0.794, 1.115],
                [-2.05, 1.735],
                [-1.142, 0.726],
                [-0.595, 0.178],
                [-0.345, 0.003],
                [-0.147, -0.201],
                [0.213, -0.331],
                [0.416, -0.514],
                [0.925, -0.836],
                [2.107, -1.242],
                [2.591, -0.658],
                [1.334, 0.175],
                [0.852, 0.883],
                [-0.735, 1],
                [-0.898, 0.864],
                [-2.154, 1.391],
                [-2.224, 1.004],
                [-2.634, 0.074],
                [-0.659, -0.185],
                [-0.079, -0.367],
                [0.259, -0.366],
                [0.765, -0.989],
                [1.927, -1.497],
                [2.121, -1.267],
                [2.484, -1.07],
                [2.661, -0.168],
                [0.611, 2.678],
                [-0.524, 1.282],
                [-0.728, 1.142],
                [-1.904, 1.783],
                [-4.63, 2.396],
                [-5.382, -0.232],
                [-0.639, -0.108],
                [0.375, -0.533],
                [0.997, -0.928],
                [2.211, -1.474],
                [4.985, -2.244],
                [2.767, -0.451],
                [0.559, 2.86],
                [-1.446, 1.647],
                [-1.919, 1.206],
                [-2.268, 0.941],
                [-2.421, 0.817],
                [-2.615, -0.118],
                [0.219, 0.028]
              ],
              "o": [
                [-3.339, -0.4],
                [-2.632, 0.96],
                [-4.678, 2.206],
                [-0.691, 1.217],
                [0.363, 1.239],
                [2.639, 1.279],
                [3.087, -1.052],
                [2.856, -1.469],
                [2.653, -1.692],
                [1.194, -1.045],
                [0.621, -0.849],
                [-0.529, -0.28],
                [-0.794, -0.087],
                [-1.52, 0.024],
                [-3.075, 0.704],
                [-5.547, 2.68],
                [-2.204, 2.13],
                [-1.09, 2.281],
                [0.908, 0.836],
                [1.449, 0.207],
                [3.05, -0.409],
                [2.773, -1.364],
                [2.411, -1.557],
                [0.994, -1.008],
                [0.43, -0.617],
                [0.442, -0.611],
                [-0.07, -1.448],
                [-1.512, -0.121],
                [-2.822, 0.918],
                [-2.599, 1.406],
                [-1.159, 0.993],
                [-1.019, 0.984],
                [-0.844, 3.255],
                [3.005, 0.154],
                [2.648, -1.313],
                [2.29, -1.782],
                [0.377, -0.447],
                [-0.173, -0.511],
                [-1.351, -0.301],
                [-1.318, 0.795],
                [-1.194, 1.043],
                [-1.053, 1.138],
                [-0.824, 1.225],
                [-0.177, 3.368],
                [1.379, -0.602],
                [1.167, -0.856],
                [0.993, -1.018],
                [0.587, -1.04],
                [-0.544, -1.206],
                [-1.465, 0.31],
                [-1.462, 1.519],
                [1.792, 1.853],
                [0.849, -0.72],
                [-0.165, -0.973],
                [-0.096, 0.017],
                [-1.187, 0.346],
                [-0.146, 1.139],
                [1.783, 1.771],
                [0.192, -0.416],
                [-0.469, 1.121],
                [-1.402, -0.15],
                [0.123, -1.147],
                [1.968, -0.092],
                [-0.876, 0.779],
                [-1.074, 0.147],
                [-0.85, -2.29],
                [0.88, -0.512],
                [1.33, 0.51],
                [-1.302, 2.123],
                [-2.029, 1.59],
                [-0.59, 0.026],
                [-0.281, -0.656],
                [0.439, -1.296],
                [1.571, -2.179],
                [0.999, -0.912],
                [0.525, -0.331],
                [0.331, -0.094],
                [0.208, 0],
                [0.3, 0.417],
                [-0.363, 0.553],
                [-0.784, 0.969],
                [-1.828, 1.625],
                [-2.243, 1.456],
                [-1.311, 0.307],
                [-1.226, -0.044],
                [-0.865, -1.2],
                [0.755, -0.99],
                [1.825, -1.801],
                [2.049, -1.326],
                [2.4, -1.091],
                [0.684, -0.033],
                [0.285, 0.086],
                [-0.001, 0.449],
                [-0.579, 1.108],
                [-1.502, 1.924],
                [-1.95, 1.517],
                [-2.289, 1.441],
                [-2.444, 1.067],
                [-1.983, 0.084],
                [-0.186, -1.372],
                [0.439, -1.281],
                [1.416, -2.19],
                [3.828, -3.537],
                [4.748, -2.565],
                [0.647, 0.013],
                [0.954, 0.183],
                [-0.791, 1.109],
                [-1.961, 1.794],
                [-4.49, 3.12],
                [-2.499, 1.272],
                [-2.086, 0.233],
                [-0.478, -2.46],
                [1.51, -1.69],
                [2.082, -1.301],
                [2.359, -1.013],
                [2.446, -0.93],
                [0.253, 0.021],
                [0, 0]
              ],
              "v": [
                [135.048, 8.585],
                [125.321, 10.246],
                [117.521, 13.422],
                [105.355, 23.831],
                [104.697, 27.847],
                [107.133, 30.62],
                [115.616, 30.527],
                [124.568, 26.619],
                [132.882, 21.762],
                [140.446, 16.134],
                [143.667, 12.649],
                [143.975, 9.62],
                [142.259, 9.186],
                [139.869, 9.071],
                [135.348, 9.588],
                [126.508, 12.853],
                [111.46, 23.379],
                [106.037, 30.724],
                [105.722, 38.848],
                [109.015, 40.32],
                [113.388, 40.377],
                [122.14, 37.65],
                [130.148, 32.997],
                [136.835, 27.609],
                [139.55, 24.351],
                [140.75, 22.432],
                [141.518, 20.348],
                [138.752, 18.476],
                [134.284, 19.031],
                [126.154, 22.604],
                [118.862, 27.604],
                [115.593, 30.804],
                [113.348, 34.342],
                [118.28, 39.027],
                [126.907, 37.037],
                [134.355, 32.272],
                [140.101, 25.829],
                [140.628, 24.221],
                [139.495, 23.221],
                [135.567, 23.928],
                [131.886, 26.696],
                [128.5, 30.032],
                [125.7, 33.732],
                [124.152, 37.82],
                [130.206, 39.908],
                [134.056, 37.587],
                [137.33, 34.745],
                [139.893, 31.355],
                [140.734, 27.602],
                [137.734, 26.569],
                [133.894, 28.701],
                [133.082, 35.001],
                [139.832, 34.354],
                [141.773, 31.093],
                [139.712, 29.628],
                [139.43, 29.7],
                [137.188, 32.274],
                [138.269, 35.352],
                [143.792, 34.058],
                [143.002, 33.658],
                [140.226, 35.522],
                [137.96, 32.713],
                [140.098, 30.464],
                [139.298, 33.579],
                [136.181, 35.043],
                [133.245, 33.843],
                [136.089, 28.189],
                [139.444, 27.406],
                [139.001, 30.925],
                [134.001, 36.383],
                [126.874, 39.573],
                [125.309, 38.937],
                [125.358, 36.894],
                [127.217, 33.257],
                [132.669, 27.364],
                [135.885, 24.902],
                [137.574, 24.135],
                [138.593, 23.989],
                [139.585, 24.175],
                [138.772, 26.041],
                [137.603, 27.641],
                [135.035, 30.354],
                [129.119, 34.665],
                [121.819, 37.857],
                [117.819, 38.057],
                [114.581, 36.612],
                [115.281, 32.812],
                [117.764, 30.028],
                [123.747, 25.228],
                [130.166, 21.728],
                [137.793, 19.361],
                [139.821, 19.591],
                [140.532, 20.149],
                [140.132, 21.402],
                [138.113, 24.553],
                [132.95, 29.704],
                [126.839, 33.883],
                [119.67, 37.655],
                [111.954, 39.523],
                [105.764, 36.989],
                [106.279, 32.937],
                [108.035, 29.29],
                [113.035, 23.308],
                [125.78, 14.368],
                [141.137, 9.985],
                [143.068, 10.167],
                [143.082, 11.767],
                [140.393, 14.831],
                [134.126, 19.74],
                [119.883, 27.803],
                [111.938, 30.403],
                [105.538, 27.61],
                [108.577, 20.881],
                [113.746, 16.515],
                [120.281, 13.145],
                [127.453, 10.345],
                [135.125, 9.113],
                [135.054, 8.585]
              ],
              "c": true
            },
            "ix": 2
          },
          "nm": "Path 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [0.709999952129, 0.709999952129, 0.709999952129, 1],
            "ix": 4
          },
          "o": { "a": 0, "k": 100, "ix": 5 },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 110,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "head",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [160, 95, 0], "ix": 2 },
        "a": { "a": 0, "k": [160, 95, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [1.84, 0.673]
                          ],
                          "o": [
                            [0, 0],
                            [0, 0]
                          ],
                          "v": [
                            [2.327, 1.79],
                            [-2.327, -1.79]
                          ],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 2,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [141.453, 69.272], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 21",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [-1.553, 0.763]
                          ],
                          "o": [
                            [0, 0],
                            [0, 0]
                          ],
                          "v": [
                            [-2.255, 2.012],
                            [2.255, -2.012]
                          ],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 2,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [172.777, 69.175], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 22",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [-2.621, -2.145]
                          ],
                          "o": [
                            [0, 0],
                            [0, 0]
                          ],
                          "v": [
                            [-2.646, 1.206],
                            [2.646, 0.939]
                          ],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [157.066, 72.363], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 23",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 3,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [-3.154, 0],
                            [0, -3.251],
                            [3.154, 0],
                            [0, 3.251]
                          ],
                          "o": [
                            [3.154, 0],
                            [0, 3.251],
                            [-3.154, 0],
                            [0, -3.251]
                          ],
                          "v": [
                            [0, -5.886],
                            [5.711, 0],
                            [0, 5.886],
                            [-5.711, 0]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [148.889, 74.326], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 24",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 4,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [-3.154, 0],
                            [0, -3.251],
                            [3.154, 0],
                            [0, 3.251]
                          ],
                          "o": [
                            [3.154, 0],
                            [0, 3.251],
                            [-3.154, 0],
                            [0, -3.251]
                          ],
                          "v": [
                            [0, -5.886],
                            [5.711, 0],
                            [0, 5.886],
                            [-5.711, 0]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 1,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [165.42, 74.326], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 25",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 5,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.079, 73.688], "ix": 2 },
                  "a": { "a": 0, "k": [157.079, 73.688], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "glass",
              "np": 5,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [4.978, 1.915],
                        [-0.331, 0.801],
                        [-0.59, -0.312],
                        [-0.997, -0.085],
                        [-0.724, 0.154]
                      ],
                      "o": [
                        [0, 0],
                        [0.332, -0.801],
                        [0.878, 0.477],
                        [0.732, 0.1],
                        [0, 0]
                      ],
                      "v": [
                        [-2.329, 0.104],
                        [-3.183, -1.218],
                        [-1.527, -1.271],
                        [1.314, -0.419],
                        [3.514, -0.501]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 25,
                        "s": [166.684, 72.606],
                        "to": [0, -0.241],
                        "ti": [0, 0.531]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [166.684, 71.068],
                        "to": [0, -0.202],
                        "ti": [0, 0.092]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 51,
                        "s": [166.684, 70.606],
                        "to": [0, -0.333],
                        "ti": [0, -0.167]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0 },
                        "t": 80,
                        "s": [166.684, 71],
                        "to": [0, 0.167],
                        "ti": [0, 0.333]
                      },
                      { "t": 100, "s": [166.684, 72.606] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "eyebrow_right",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-4.509, 2.821],
                        [0.478, 0.723],
                        [0.517, -0.415],
                        [0.961, -0.271],
                        [0.739, 0.015]
                      ],
                      "o": [
                        [0, 0],
                        [-0.478, -0.723],
                        [-0.77, 0.636],
                        [-0.7, 0.238],
                        [0, 0]
                      ],
                      "v": [
                        [2.393, -0.32],
                        [2.978, -1.779],
                        [1.345, -1.519],
                        [-1.281, -0.144],
                        [-3.456, 0.193]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 25,
                        "s": [147.262, 72.574],
                        "to": [0, -0.241],
                        "ti": [0, 0.531]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [147.262, 71.036],
                        "to": [0, -0.202],
                        "ti": [0, 0.092]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 51,
                        "s": [147.262, 70.574],
                        "to": [0, -0.333],
                        "ti": [0, 0.167]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0 },
                        "t": 80,
                        "s": [147.262, 71],
                        "to": [0, -0.167],
                        "ti": [0, 0.333]
                      },
                      { "t": 100, "s": [147.262, 72.574] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "eyebrow_left",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.014, -0.931],
                        [-0.578, 0],
                        [-0.006, 0.926],
                        [0.578, 0]
                      ],
                      "o": [
                        [-0.023, 0.93],
                        [0.578, 0],
                        [0.007, -0.926],
                        [-0.577, 0]
                      ],
                      "v": [
                        [-1.026, -0.017],
                        [-0.019, 1.69],
                        [1.042, 0.017],
                        [0.036, -1.69]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 25,
                        "s": [164.298, 75.883],
                        "to": [0, -0.333],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 35,
                        "s": [164.298, 73.883],
                        "to": [0, 0],
                        "ti": [0, -0.333]
                      },
                      { "t": 100, "s": [164.298, 75.883] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833, 0.833], "y": [0.833, 0.833] },
                        "o": { "x": [0.167, 0.167], "y": [0.167, 0.167] },
                        "t": 25,
                        "s": [100, 120]
                      },
                      {
                        "i": { "x": [0.833, 0.833], "y": [0.833, 0.833] },
                        "o": { "x": [0.167, 0.167], "y": [0.167, 0.167] },
                        "t": 35,
                        "s": [110, 110]
                      },
                      { "t": 100, "s": [100, 120] }
                    ],
                    "ix": 3
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "eye_right",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.011, -0.93],
                        [0.573, 0],
                        [0.009, 0.926],
                        [-0.571, 0]
                      ],
                      "o": [
                        [0.024, 0.931],
                        [-0.573, 0],
                        [-0.009, -0.926],
                        [0.571, 0]
                      ],
                      "v": [
                        [1.025, -0.018],
                        [0.025, 1.69],
                        [-1.039, 0.016],
                        [-0.039, -1.69]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 25,
                        "s": [149.584, 76.072],
                        "to": [0, -0.333],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 35,
                        "s": [149.584, 74.072],
                        "to": [0, 0],
                        "ti": [0, -0.333]
                      },
                      { "t": 100, "s": [149.584, 76.072] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833, 0.833], "y": [0.833, 0.833] },
                        "o": { "x": [0.167, 0.167], "y": [0.167, 0.167] },
                        "t": 25,
                        "s": [100, 120]
                      },
                      {
                        "i": { "x": [0.833, 0.833], "y": [0.833, 0.833] },
                        "o": { "x": [0.167, 0.167], "y": [0.167, 0.167] },
                        "t": 35,
                        "s": [110, 110]
                      },
                      { "t": 100, "s": [100, 120] }
                    ],
                    "ix": 3
                  },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "eye_left",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [3.077, -2.302]
                      ],
                      "o": [
                        [-3.273, -2.013],
                        [0, 0]
                      ],
                      "v": [
                        [5.261, 0.767],
                        [-5.26, 1.246]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.246999987434, 0.20800000359, 0.219999994016, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 0.9, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 25,
                        "s": [157.794, 85.186],
                        "to": [0, -0.064],
                        "ti": [0, 0.064]
                      },
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 30,
                        "s": [157.794, 84.8],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 35,
                        "s": [157.794, 84.8],
                        "to": [0, 0.1],
                        "ti": [0, -0.064]
                      },
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 45,
                        "s": [157.794, 85.4],
                        "to": [0, 0.064],
                        "ti": [0, 0.036]
                      },
                      { "t": 100, "s": [157.794, 85.186] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 35,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.833], "y": [0.833] },
                        "o": { "x": [0.167], "y": [0.167] },
                        "t": 45,
                        "s": [-2]
                      },
                      { "t": 60, "s": [-2] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 50, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "mouse",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 37,
                    "s": [157.079, 76.996],
                    "to": [0.203, 0],
                    "ti": [-0.203, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 49,
                    "s": [158.3, 76.996],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 65,
                    "s": [158.3, 76.996],
                    "to": [-0.203, 0],
                    "ti": [0.203, 0]
                  },
                  { "t": 77, "s": [157.079, 76.996] }
                ],
                "ix": 2
              },
              "a": { "a": 0, "k": [157.079, 76.996], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 49,
                    "s": [3]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 65,
                    "s": [3]
                  },
                  { "t": 77, "s": [0] }
                ],
                "ix": 6
              },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "face",
          "np": 6,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-1.258, 0.277],
                        [-0.559, 0.324],
                        [0.154, 0.253],
                        [0, 0],
                        [0.255, -0.156],
                        [1.426, 0.687],
                        [0.137, -0.257],
                        [0, 0],
                        [-0.265, -0.135]
                      ],
                      "o": [
                        [0.633, -0.125],
                        [0.253, -0.154],
                        [0, 0],
                        [-0.158, -0.254],
                        [-1.375, 0.785],
                        [-0.261, -0.13],
                        [0, 0],
                        [-0.135, 0.265],
                        [1.148, 0.584]
                      ],
                      "v": [
                        [0.98, 0.736],
                        [2.779, 0.058],
                        [2.958, -0.68],
                        [2.958, -0.681],
                        [2.212, -0.857],
                        [-2.262, -0.702],
                        [-2.977, -0.474],
                        [-2.977, -0.462],
                        [-2.742, 0.262]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.47, 89.581], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 19",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-1, -0.051],
                        [-1.89, 0.243],
                        [-0.35, 0.041],
                        [-0.643, -0.059],
                        [-1.665, 0.099],
                        [-0.231, 0.041],
                        [-0.555, -0.059],
                        [-0.6, 0.033],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [1.904, 0.07],
                        [0.449, -0.24],
                        [0.35, -0.041],
                        [1.665, 0.099],
                        [0.551, -0.137],
                        [0.231, -0.041],
                        [0.555, 0.059],
                        [0.601, -0.033],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-9.558, -0.311],
                        [-9.858, 4.889],
                        [-4.158, 4.628],
                        [-3.158, 0.788],
                        [-2.408, 4.628],
                        [2.592, 4.628],
                        [3.461, 0.788],
                        [4.747, 4.628],
                        [10.258, 4.895],
                        [9.258, -1.948],
                        [-2.181, -4.96]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.413, 59.577], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 20",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.466, 2.946],
                        [15.863, -0.77],
                        [0.183, -6.665],
                        [-0.186, -2.23],
                        [-1.927, 3.255],
                        [-9.032, 0],
                        [-3.201, -4.455],
                        [-0.109, -2.989],
                        [-0.047, 1.684]
                      ],
                      "o": [
                        [0, 0],
                        [-14.007, 0.677],
                        [-0.1, 3.669],
                        [0.265, -1.616],
                        [2.433, -4.122],
                        [5.076, 0],
                        [2.488, 3.465],
                        [0.243, -2.1],
                        [0.275, -2.971]
                      ],
                      "v": [
                        [17.6, -3.04],
                        [-0.142, -15.013],
                        [-18.063, 1.087],
                        [-17.084, 13.555],
                        [-14.728, 5.121],
                        [0.039, -2.746],
                        [13.756, 3.747],
                        [17.197, 15.783],
                        [17.887, 5.87]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.146, 61.285], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 30",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [-0.793, -0.001],
                        [-0.14, 0.044],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.001, 0.792],
                        [0.146, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-1.046, -3.247],
                        [-1.689, 1.811],
                        [-0.255, 3.247],
                        [0.177, 3.18],
                        [1.69, 2.781]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.917999985639, 0.545000023935, 0.442999985639, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 1, "ix": 5 },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.478, 78.092], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 32",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [1.474, -0.112],
                        [0.111, 1.474],
                        [-1.475, 0.111],
                        [0, 0],
                        [-0.071, 0],
                        [-0.054, -1.419]
                      ],
                      "o": [
                        [0.111, 1.474],
                        [-1.474, 0.111],
                        [-0.111, -1.474],
                        [0, 0],
                        [0.066, 0],
                        [1.419, -0.052],
                        [0, 0]
                      ],
                      "v": [
                        [2.67, -0.227],
                        [0.202, 2.644],
                        [-2.669, 0.176],
                        [-0.201, -2.695],
                        [-0.193, -2.695],
                        [0.007, -2.703],
                        [2.673, -0.228]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [166.198, 79.495], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 33",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.055, -1.417],
                        [1.474, -0.111],
                        [0.111, 1.474],
                        [-1.474, 0.111],
                        [0, 0],
                        [-0.07, 0]
                      ],
                      "o": [
                        [0.111, 1.474],
                        [-1.474, 0.111],
                        [-0.111, -1.474],
                        [0, 0],
                        [0.065, 0],
                        [1.417, -0.048]
                      ],
                      "v": [
                        [2.67, -0.23],
                        [0.202, 2.641],
                        [-2.669, 0.174],
                        [-0.201, -2.698],
                        [-0.191, -2.698],
                        [0.008, -2.705]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [146.904, 79.499], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 34",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -11.684],
                        [3.121, -4.192],
                        [4.9, 0.138],
                        [2.9, 4.701],
                        [-0.041, 4.614],
                        [-17.268, 0]
                      ],
                      "o": [
                        [0.065, 5.227],
                        [-2.983, 3.889],
                        [-5.523, 0.086],
                        [-2.446, -3.912],
                        [0, -11.683],
                        [17.268, 0]
                      ],
                      "v": [
                        [17.253, 0.263],
                        [12.541, 14.785],
                        [-0.01, 20.757],
                        [-13.594, 13.314],
                        [-17.277, 0.259],
                        [-0.009, -20.895]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.336, 73.578], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 35",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [1.911, -2.322]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-1.447, -1.934],
                        [-0.464, 1.934]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 0.674, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [176.825, 73.115], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 36",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [1.891, -3.908]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [2.273, -1.069],
                        [-2.274, 1.954]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 0.674, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [176.358, 71.139], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 37",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [3.577, 2.916],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [3.672, 2.711],
                        [-3.578, -2.916],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-4.843, 6.364],
                        [1.594, -6.159],
                        [-4.343, -3.73],
                        [-4.432, 0.794],
                        [-4.966, 5.042],
                        [-5.171, 6.348]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [178.03, 72.517], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 38",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 10,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [-0.035, -0.106]
                      ],
                      "o": [
                        [0, 0],
                        [0.038, 0.107],
                        [0, 0]
                      ],
                      "v": [
                        [0.038, -0.132],
                        [-0.051, -0.161],
                        [0.05, 0.161]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [165.893, 88.602], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 40",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 11,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-5.376, 0],
                        [0, -3.668],
                        [5.376, 0],
                        [0, 3.668]
                      ],
                      "o": [
                        [5.376, 0],
                        [0, 3.668],
                        [-5.376, 0],
                        [0, -3.668]
                      ],
                      "v": [
                        [0, -6.642],
                        [9.734, 0],
                        [0, 6.642],
                        [-9.734, 0]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.172, 50.723], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 25, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 42",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 12,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-5.621, 0],
                        [0, -5.604],
                        [5.622, 0],
                        [0, 5.603]
                      ],
                      "o": [
                        [5.622, 0],
                        [0, 5.603],
                        [-5.621, 0],
                        [0, -5.604]
                      ],
                      "v": [
                        [0, -10.146],
                        [10.178, 0],
                        [0, 10.146],
                        [-10.178, 0]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [157.487, 44.469], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 43",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 13,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-1.91, -2.322]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.447, -1.933],
                        [0.464, 1.933]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 0.674, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [137.492, 73.181], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 44",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 14,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-1.891, -3.908]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-2.273, -1.069],
                        [2.273, 1.954]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [1, 0.635000011968, 0.517999985639, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 0.674, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [137.952, 71.206], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 45",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 15,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-3.577, 2.92],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [-3.661, 2.71],
                        [3.577, -2.92],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [4.846, 6.37],
                        [-1.591, -6.16],
                        [4.232, -3.676],
                        [4.432, 0.795],
                        [4.968, 5.043],
                        [5.168, 6.348]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [136.277, 72.577], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 46",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 16,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 37,
                    "s": [160, 95],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 49,
                    "s": [160, 95],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 65,
                    "s": [160, 95],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  { "t": 77, "s": [160, 95] }
                ],
                "ix": 2
              },
              "a": { "a": 0, "k": [160, 95], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 49,
                    "s": [3]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 65,
                    "s": [3]
                  },
                  { "t": 77, "s": [0] }
                ],
                "ix": 6
              },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "head",
          "np": 16,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-4.2, 0],
                    [-0.856, 3.333],
                    [0, 0],
                    [0.041, 0.106],
                    [0, 0],
                    [0.612, -1.876],
                    [0, 0]
                  ],
                  "o": [
                    [4.2, 0],
                    [0, 0],
                    [-0.031, -0.106],
                    [0, 0],
                    [-1.744, 0.922],
                    [0, 0],
                    [0.867, 3.328]
                  ],
                  "v": [
                    [0, 6.809],
                    [8.567, 0.977],
                    [8.485, -2.455],
                    [8.385, -2.774],
                    [-4.826, -6.81],
                    [-8.5, -2.448],
                    [-8.567, 0.993]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.128999986836, 0.097999999102, 0.105999995213, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [157.455, 91.212], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 45, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "neck_shadow",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [1.542, -0.104],
                    [0, 0],
                    [3.878, 2.472],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-1.507, 0.337],
                    [0, 0],
                    [-4.599, 0.088],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.83, 10.925],
                    [8.77, 10.943],
                    [4.189, 11.605],
                    [4.171, 11.605],
                    [-8.829, 7.949],
                    [-8.645, -1.526],
                    [-8.443, -11.694],
                    [-6.105, -10.983],
                    [8.426, -6.55],
                    [8.705, 5.279]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [157.485, 94.385], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "neck",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [7.621, -0.768],
                    [10.533, -4.646],
                    [-18.187, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [18.186, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-0.172, -6.253],
                    [-18.778, -2.044],
                    [-0.812, 7.021],
                    [18.999, -2.913]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 37,
                    "s": [158.185, 104.331],
                    "to": [0, -0.222],
                    "ti": [0, 0.222]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 47,
                    "s": [158.185, 103],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 67,
                    "s": [158.185, 103],
                    "to": [0, 0.222],
                    "ti": [0, -0.222]
                  },
                  { "t": 77, "s": [158.185, 104.331] }
                ],
                "ix": 2
              },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "neck_bottom",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 110,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "body",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [160, 95, 0], "ix": 2 },
        "a": { "a": 0, "k": [160, 95, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0.724, -3.872]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-0.946, -4.277],
                    [0.221, 4.277]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [128.424, 113.569], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 35, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "line_left",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [-0.722, -7.571]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.298, -5.673],
                    [-1.576, 5.673]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 3 },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [190.934, 113.252], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 35, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "line_right",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-4.623, -4.518],
                    [-1.912, 1.399],
                    [5.632, 7.469],
                    [5.294, 1.878],
                    [2.008, -0.378],
                    [-3.887, -8.545]
                  ],
                  "o": [
                    [3.666, -0.393],
                    [7.185, -5.236],
                    [-4.587, -3.355],
                    [-0.894, -0.083],
                    [0.491, 4.87],
                    [2.067, 4.544]
                  ],
                  "v": [
                    [-1.026, 17.238],
                    [9.943, 14.49],
                    [4.428, -11.402],
                    [-12.598, -17.155],
                    [-17.128, -16.607],
                    [-11.311, 3.949]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.7254901960784313, 0, 0.1411764705882353, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 37,
                    "s": [189.868, 118.594],
                    "to": [0.033, -0.266],
                    "ti": [-0.033, 0.266]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 47,
                    "s": [190.068, 117],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 67,
                    "s": [190.068, 117],
                    "to": [-0.033, 0.266],
                    "ti": [0.033, -0.266]
                  },
                  { "t": 77, "s": [189.868, 118.594] }
                ],
                "ix": 2
              },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 47,
                    "s": [-2.885]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 67,
                    "s": [-2.885]
                  },
                  { "t": 77, "s": [0] }
                ],
                "ix": 6
              },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "right",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0.579, 6.053],
                    [0.775, -0.245],
                    [3.819, -3.139],
                    [0, 0],
                    [0.484, -0.431],
                    [0.691, -0.674],
                    [0, 0],
                    [-0.635, -6.283],
                    [-6.353, -1.024],
                    [-0.006, -0.031],
                    [-1.941, 4.925]
                  ],
                  "o": [
                    [-0.781, 0.057],
                    [-4.111, 1.285],
                    [0, 0],
                    [0, 0],
                    [-0.708, 0.609],
                    [0, 0],
                    [-2.348, 2.3],
                    [0.358, 3.582],
                    [0.007, 0.031],
                    [3.449, -4.35],
                    [3.013, -7.651]
                  ],
                  "v": [
                    [12.169, -17.774],
                    [9.834, -17.337],
                    [-2.956, -12.411],
                    [-2.956, -12.433],
                    [-3.705, -11.789],
                    [-5.805, -9.876],
                    [-5.805, -9.832],
                    [-12.113, 6.243],
                    [-0.04, 17.678],
                    [-0.022, 17.773],
                    [8.352, 3.786]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.7254901960784313, 0, 0.1411764705882353, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 37,
                    "s": [129.565, 119.625],
                    "to": [-0.111, -0.271],
                    "ti": [0.111, 0.271]
                  },
                  {
                    "i": { "x": 0.667, "y": 0.667 },
                    "o": { "x": 0.333, "y": 0.333 },
                    "t": 47,
                    "s": [128.898, 118],
                    "to": [0, 0],
                    "ti": [0, 0]
                  },
                  {
                    "i": { "x": 0.667, "y": 1 },
                    "o": { "x": 0.333, "y": 0 },
                    "t": 67,
                    "s": [128.898, 118],
                    "to": [0.111, 0.271],
                    "ti": [-0.111, -0.271]
                  },
                  { "t": 77, "s": [129.565, 119.625] }
                ],
                "ix": 2
              },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 47,
                    "s": [3]
                  },
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 67,
                    "s": [3]
                  },
                  { "t": 77, "s": [0] }
                ],
                "ix": 6
              },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "left",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [-2.085, 0.803]
                          ],
                          "o": [
                            [0.251, 2.39],
                            [0, 0]
                          ],
                          "v": [
                            [-3.59, -2.17],
                            [3.589, 1.367]
                          ],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.569000004787, 0.383999992819, 0.388000009574, 1
                        ],
                        "ix": 3
                      },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 2,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [90.648, 113.103], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 30, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 49",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [-2.342, 2.528],
                            [1.253, 1.267],
                            [0.062, 0.034],
                            [0, 0],
                            [3.498, -0.506],
                            [1.154, 1.837],
                            [1.368, -0.864],
                            [-0.122, -1.113],
                            [-0.268, -0.39],
                            [0, 0],
                            [-1.125, 0.06],
                            [0, 0],
                            [0, 0]
                          ],
                          "o": [
                            [0.184, -1.771],
                            [-0.057, -0.042],
                            [0, 0],
                            [-2.488, -1.733],
                            [-2.146, 0.313],
                            [-0.863, -1.367],
                            [-0.947, 0.598],
                            [0.052, 0.471],
                            [0, 0],
                            [0.638, 0.93],
                            [0, 0],
                            [0, 0],
                            [3.278, -0.208]
                          ],
                          "v": [
                            [11.911, 3.273],
                            [10.223, -1.527],
                            [10.045, -1.641],
                            [10.068, -1.706],
                            [-1.156, -2.553],
                            [-6.585, -5.061],
                            [-10.624, -5.973],
                            [-11.973, -3.176],
                            [-11.484, -1.863],
                            [-7.255, 4.321],
                            [-4.419, 5.721],
                            [2.3, 6.773],
                            [2.318, 6.837]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.948999980852, 0.776000019148, 0.642999985639, 1
                        ],
                        "ix": 4
                      },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [93.407, 112.774], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 50",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [2.368, 1.26],
                            [0.387, 0.441],
                            [0, 0],
                            [-0.445, 0.677],
                            [-0.794, -0.459],
                            [0, 0],
                            [0, 0]
                          ],
                          "o": [
                            [-1.26, 2.368],
                            [-0.519, -0.276],
                            [0, 0],
                            [-0.569, -0.576],
                            [0.504, -0.765],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          "v": [
                            [5.634, 1.009],
                            [-0.935, 3.016],
                            [-2.305, 1.932],
                            [-4.976, -1.123],
                            [-5.189, -3.272],
                            [-2.878, -3.818],
                            [1.055, -1.563],
                            [5.633, 1.01]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.948999980852, 0.776000019148, 0.642999985639, 1
                        ],
                        "ix": 4
                      },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [97.112, 109.832], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 51",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 3,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [160.024, 94.4],
                        "to": [-1.915, -0.687],
                        "ti": [1.915, 0.687]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [148.535, 90.276],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [148.535, 90.276],
                        "to": [1.915, 0.687],
                        "ti": [-1.915, -0.687]
                      },
                      { "t": 77, "s": [160.024, 94.4] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [160, 95], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [-5.075]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [-5.075]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "hand",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [-0.825, 3.128],
                        [2.612, -0.075],
                        [0, 0]
                      ],
                      "o": [
                        [-2.273, 1.288],
                        [0, 0],
                        [3.006, -1.196]
                      ],
                      "v": [
                        [3.72, -3.438],
                        [-3.72, -1.363],
                        [-2.371, 3.438]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.757000014361, 0.57599995931, 0.513999968884, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [100.033, 119.973],
                        "to": [-1.422, 0.147],
                        "ti": [1.422, -0.147]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [91.503, 120.855],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [91.503, 120.855],
                        "to": [1.422, -0.147],
                        "ti": [-1.422, 0.147]
                      },
                      { "t": 77, "s": [100.033, 119.973] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [-10.393]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [-10.393]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "shadow",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.031, 0.085],
                        [0, 0],
                        [0.027, 0.067],
                        [0, 0],
                        [0, 0],
                        [3.076, -0.195],
                        [0, 0],
                        [-3.967, 1.12],
                        [-0.073, 0.023],
                        [0.954, 3.005]
                      ],
                      "o": [
                        [-0.027, -0.084],
                        [0, 0],
                        [-0.027, -0.066],
                        [0, 0],
                        [0, 0],
                        [-2.2, 2.375],
                        [0, 0],
                        [1.133, 3.964],
                        [0.076, -0.022],
                        [3.004, -0.956],
                        [0, 0]
                      ],
                      "v": [
                        [11.112, 14.283],
                        [11.025, 14.03],
                        [-2.527, -21.423],
                        [-2.616, -21.612],
                        [-3.064, -22.632],
                        [-3.064, -22.655],
                        [-12.072, -19.308],
                        [-2.045, 16.391],
                        [7.18, 21.535],
                        [7.406, 21.468],
                        [11.118, 14.298]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [108.384, 137.915],
                        "to": [-0.889, -0.167],
                        "ti": [0.889, 0.167]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [103.05, 136.915],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [103.05, 136.915],
                        "to": [0.889, 0.167],
                        "ti": [-0.889, -0.167]
                      },
                      { "t": 77, "s": [108.384, 137.915] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [-10.816]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [-10.816]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "front",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.145, 1.362],
                        [0, 0],
                        [-1.349, 3.133],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.377, 3.138],
                        [0, 0],
                        [-3.133, -1.349],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-11.17, 8.947],
                        [1.451, -19.146],
                        [12.531, -5.354],
                        [1.831, 14.573],
                        [-6.345, 17.784],
                        [-7.951, 17.084],
                        [-11.182, 8.968],
                        [-11.172, 8.947]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.859000052658, 0.689999988032, 0.560999971278, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [117.971, 141.334],
                        "to": [-0.278, -0.333],
                        "ti": [0.278, 0.333]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [116.304, 139.334],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [116.304, 139.334],
                        "to": [0.278, 0.333],
                        "ti": [-0.278, -0.333]
                      },
                      { "t": 77, "s": [117.971, 141.334] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [3.673]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [3.673]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "bg",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160, 95], "ix": 2 },
              "a": { "a": 0, "k": [160, 95], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Arm_left",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [2.085, 0.803]
                          ],
                          "o": [
                            [-0.251, 2.39],
                            [0, 0]
                          ],
                          "v": [
                            [3.59, -2.17],
                            [-3.59, 1.367]
                          ],
                          "c": false
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.569000004787, 0.383999992819, 0.388000009574, 1
                        ],
                        "ix": 3
                      },
                      "o": { "a": 0, "k": 100, "ix": 4 },
                      "w": { "a": 0, "k": 1, "ix": 5 },
                      "lc": 2,
                      "lj": 1,
                      "ml": 10,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [229.271, 113.103], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 30, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 55",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [2.343, 2.528],
                            [-1.252, 1.267],
                            [-0.061, 0.034],
                            [0, 0],
                            [-3.498, -0.506],
                            [-1.153, 1.837],
                            [-1.368, -0.864],
                            [0.122, -1.113],
                            [0.267, -0.39],
                            [0, 0],
                            [1.125, 0.06],
                            [0, 0],
                            [0, 0]
                          ],
                          "o": [
                            [-0.184, -1.771],
                            [0.057, -0.042],
                            [0, 0],
                            [2.487, -1.733],
                            [2.147, 0.313],
                            [0.864, -1.367],
                            [0.946, 0.598],
                            [-0.053, 0.471],
                            [0, 0],
                            [-0.637, 0.93],
                            [0, 0],
                            [0, 0],
                            [-3.281, -0.209]
                          ],
                          "v": [
                            [-11.911, 3.273],
                            [-10.223, -1.527],
                            [-10.045, -1.641],
                            [-10.067, -1.706],
                            [1.155, -2.553],
                            [6.584, -5.061],
                            [10.625, -5.973],
                            [11.973, -3.176],
                            [11.485, -1.863],
                            [7.255, 4.321],
                            [4.418, 5.721],
                            [-2.299, 6.773],
                            [-2.316, 6.837]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.948999980852, 0.776000019148, 0.642999985639, 1
                        ],
                        "ix": 4
                      },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [226.512, 112.774], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 56",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [0, 0],
                            [-2.368, 1.26],
                            [-0.387, 0.441],
                            [0, 0],
                            [0.445, 0.677],
                            [0.794, -0.459],
                            [0, 0],
                            [0, 0]
                          ],
                          "o": [
                            [1.26, 2.368],
                            [0.519, -0.276],
                            [0, 0],
                            [0.569, -0.576],
                            [-0.504, -0.765],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          "v": [
                            [-5.634, 1.009],
                            [0.935, 3.017],
                            [2.305, 1.932],
                            [4.976, -1.122],
                            [5.189, -3.271],
                            [2.878, -3.818],
                            [-1.055, -1.563],
                            [-5.633, 1.011]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.948999980852, 0.776000019148, 0.642999985639, 1
                        ],
                        "ix": 4
                      },
                      "o": { "a": 0, "k": 100, "ix": 5 },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": { "a": 0, "k": [222.807, 109.831], "ix": 2 },
                      "a": { "a": 0, "k": [0, 0], "ix": 1 },
                      "s": { "a": 0, "k": [100, 100], "ix": 3 },
                      "r": { "a": 0, "k": 0, "ix": 6 },
                      "o": { "a": 0, "k": 100, "ix": 7 },
                      "sk": { "a": 0, "k": 0, "ix": 4 },
                      "sa": { "a": 0, "k": 0, "ix": 5 },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 57",
                  "np": 2,
                  "cix": 2,
                  "bm": 0,
                  "ix": 3,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [160.8, 92.8],
                        "to": [1.292, -0.792],
                        "ti": [-1.292, 0.792]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [168.55, 88.05],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [168.55, 88.05],
                        "to": [-1.292, 0.792],
                        "ti": [1.292, -0.792]
                      },
                      { "t": 77, "s": [160.8, 92.8] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [160, 95], "ix": 1 },
                  "s": { "a": 0, "k": [98.316, 109.979], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [2.298]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [2.298]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "hand",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.825, 3.128],
                        [-2.612, -0.075],
                        [0, 0]
                      ],
                      "o": [
                        [2.273, 1.288],
                        [0, 0],
                        [-3.006, -1.196]
                      ],
                      "v": [
                        [-3.72, -3.438],
                        [3.72, -1.363],
                        [2.371, 3.438]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.757000014361, 0.57599995931, 0.513999968884, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [219.886, 119.973],
                        "to": [1.091, -0.364],
                        "ti": [-1.091, 0.364]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [226.431, 117.791],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [226.431, 117.791],
                        "to": [-1.091, 0.364],
                        "ti": [1.091, -0.364]
                      },
                      { "t": 77, "s": [219.886, 119.973] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [5.429]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [5.429]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "shadow",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.03, 0.085],
                        [0, 0],
                        [-0.028, 0.067],
                        [0, 0],
                        [0, 0],
                        [-3.077, -0.195],
                        [0, 0],
                        [3.966, 1.12],
                        [0.073, 0.023],
                        [-0.954, 3.005]
                      ],
                      "o": [
                        [0.027, -0.084],
                        [0, 0],
                        [0.026, -0.066],
                        [0, 0],
                        [0, 0],
                        [2.2, 2.375],
                        [0, 0],
                        [-1.133, 3.964],
                        [-0.077, -0.022],
                        [-3.005, -0.956],
                        [0, 0]
                      ],
                      "v": [
                        [-11.112, 14.283],
                        [-11.025, 14.03],
                        [2.528, -21.423],
                        [2.616, -21.612],
                        [3.064, -22.632],
                        [3.064, -22.655],
                        [12.072, -19.308],
                        [2.044, 16.391],
                        [-7.18, 21.535],
                        [-7.406, 21.468],
                        [-11.118, 14.298]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.948999980852, 0.776000019148, 0.642999985639, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [211.536, 137.915],
                        "to": [0.833, -0.5],
                        "ti": [-0.833, 0.5]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [216.536, 134.915],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [216.536, 134.915],
                        "to": [-0.833, 0.5],
                        "ti": [0.833, -0.5]
                      },
                      { "t": 77, "s": [211.536, 137.915] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [5.259]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [5.259]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "front",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.145, 1.362],
                        [0, 0],
                        [1.348, 3.133],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.377, 3.138],
                        [0, 0],
                        [3.133, -1.349],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [11.171, 8.947],
                        [-1.451, -19.147],
                        [-12.531, -5.355],
                        [-1.831, 14.574],
                        [6.345, 17.784],
                        [7.951, 17.084],
                        [11.182, 8.968],
                        [11.173, 8.947]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.859000052658, 0.689999988032, 0.560999971278, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 37,
                        "s": [201.948, 141.333],
                        "to": [0.333, -0.444],
                        "ti": [-0.333, 0.444]
                      },
                      {
                        "i": { "x": 0.667, "y": 0.667 },
                        "o": { "x": 0.333, "y": 0.333 },
                        "t": 47,
                        "s": [203.948, 138.666],
                        "to": [0, 0],
                        "ti": [0, 0]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 67,
                        "s": [203.948, 138.666],
                        "to": [-0.333, 0.444],
                        "ti": [0.333, -0.444]
                      },
                      { "t": 77, "s": [201.948, 141.333] }
                    ],
                    "ix": 2
                  },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 37,
                        "s": [0]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 47,
                        "s": [-7.622]
                      },
                      {
                        "i": { "x": [0.667], "y": [1] },
                        "o": { "x": [0.333], "y": [0] },
                        "t": 67,
                        "s": [-7.622]
                      },
                      { "t": 77, "s": [0] }
                    ],
                    "ix": 6
                  },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "bg",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160, 95], "ix": 2 },
              "a": { "a": 0, "k": [160, 95], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Arm_right",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 6,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [4.471, 0.017],
                    [5.456, -0.565],
                    [3.522, -9.485],
                    [-0.13, -6.86],
                    [-0.178, -3.779],
                    [-0.06, -4.559],
                    [-1.457, -1.33],
                    [-4.971, 1.411],
                    [0.427, 6.91],
                    [-0.218, 4.026],
                    [-0.771, 4.434],
                    [-0.057, 0.007],
                    [4.851, 7.819]
                  ],
                  "o": [
                    [-5.624, 0.785],
                    [-2.048, 2.485],
                    [-1.776, 4.785],
                    [0.752, 3.707],
                    [0.184, 4.279],
                    [0.058, 4.684],
                    [4.612, 4.221],
                    [1.899, -0.541],
                    [-0.245, -4.024],
                    [0.257, -4.492],
                    [0.057, -0.006],
                    [-3.773, -12.772],
                    [-4.246, 0.8]
                  ],
                  "v": [
                    [-2.26, -35.126],
                    [-18.949, -37.372],
                    [-28.671, -19.64],
                    [-30.678, -1.265],
                    [-29.269, 9.978],
                    [-27.508, 23.378],
                    [-28.452, 33.716],
                    [28.908, 33.716],
                    [26.863, 22.068],
                    [26.822, 9.983],
                    [28.365, -3.417],
                    [28.539, -3.437],
                    [12.44, -37.284]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.7254901960784313, 0, 0.1411764705882353, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160.337, 139.265], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "body",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 7,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 110,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
